<template>
  <div class="info-popup popup-detail">
  <el-dialog
    :title="todo == 'add' ? '【营销跟踪】新增跟踪填报' : (todo == 'edit' ? '【修改】出差费用' : '【查看】出差费用')"
    :close-on-click-modal="false"
    width="60%"
    top="50px"
    :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" label-width="120px" size="medium">
      <div class="boxSize">
        <div class="infoBox infoBox1" style="max-height: 100%">
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item label="跟踪客户" prop="customerId">
                <el-select :disabled="isViews" class="item-choose" v-model="dataForm.customerId" filterable clearable
                           placeholder="请选择"
                           @change="changeCustomer(false)">
                  <el-option v-for="item in customerList" :key="item.id"
                             :label="item.simpleName" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户联系人">
                <el-autocomplete :disabled="isViews" class="inline-input" v-model="dataForm.customerContactName"
                                 :fetch-suggestions="queryNameSearch"
                                 value-key="contactName"
                                 style="width:100%"
                                 clearable
                                 placeholder="客户联系人"
                                 @select="nameSelect"></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话">
                <el-input :disabled="isViews" v-model="dataForm.customerContactPhone" placeholder="联系电话"  show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item label="职位">
                <el-input :disabled="isViews" v-model="dataForm.customerContactPosition" placeholder="职位"  show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="客户反馈方式" prop="marketTrackType">
                <el-radio v-model="dataForm.marketTrackType" label="1">短信</el-radio>
                <el-radio v-model="dataForm.marketTrackType" label="2">微信</el-radio>
                <el-radio v-model="dataForm.marketTrackType" label="3">电话</el-radio>
                <el-radio v-model="dataForm.marketTrackType" label="4">其他</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="16">
              <el-form-item label="客户反馈内容" prop="content" :style="{ display: isShow ? 'block' : 'none' }">
                <el-input type="textarea" :rows="5" v-model="dataForm.content"
                          placeholder="客户反馈内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="附件上传" prop="enclosureInfoList" :style="{ display: isShow ? 'block' : 'none' }">
                <al-upload :fileData="dataForm.enclosureInfoList"
                           :fileUrl="uploadUrl"
                           :format="'file'"
                           :type="'offer'"
                           :limit="20"
                           :businessType="2003"
                           @getFileData="getFileData"></al-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item label="填报人姓名" prop="fillName">
                <el-input v-model="dataForm.fillName" :disabled="true" placeholder="填报人姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="填报时间" prop="fillTime">
                <el-date-picker :disabled="isViews"
                  v-model="dataForm.fillTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="费用类型" prop="payType">
                <el-select v-model="dataForm.payType" filterable clearable placeholder="请选择" :disabled="isViews">
                  <el-option
                    v-for="item in  dictTypeMap.pay_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item label="金额" prop="money">
                <el-input v-model="dataForm.money" placeholder="金额" :disabled="isViews"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="费用备注" prop="remark">
                <el-input v-model="dataForm.remark" placeholder="费用备注" :disabled="isViews"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item label="票据上传" prop="enclosureImgList" class="noDate">
                <!--                <al-upload :fileData="dataForm.enclosureImgList"-->
                <!--                           :fileUrl="uploadUrl"-->
                <!--                           :format="'img'"-->
                <!--                           :type="'offer'"-->
                <!--                           :limit="20"-->
                <!--                           :businessType="imgTypeList.money"-->
                <!--                           @getFileData="getImgData">-->
                <!--                </al-upload>-->
                <al-upload-card
                  :fileData="dataForm.enclosureImgList"
                  :format="'img'"
                  :fileUrl="uploadUrl"
                  :businessType="imgTypeList.money"
                  :type="'offer'"
                  :limit="20"
                  @getFileData="getImgData"
                  @handlePictureCardPreview="handlePictureCardPreview"></al-upload-card>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结算类型" prop="paymentType">
                <el-select v-model="dataForm.paymentType" filterable clearable placeholder="请选择" :disabled="isViews">
                  <el-option
                    v-for="item in  dictTypeMap.payment_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit(todo)" :disabled="isViews">确定</el-button>
    </span>
  </el-dialog>
    <!-- 查看图片 -->
    <big-image ref="bigImg"></big-image>
  </div>
</template>

<script>
import qs from 'qs'
import bigImage from '@/views/commonPop/bigImage'
export default {
  data () {
    return {
      visible: false,
      isCustomer: false,
      customerList: [],
      contactNameOption: [],
      uploadUrl: '',
      accessToken: null,
      licenseFileList: [],
      imgTypeList: {
        money: [7011] // 出差费
      },
      dataForm: {
        id: null,
        customerId: null,
        customerContactName: null,
        customerContactPhone: null,
        customerContactPosition: null,
        customerContactId: null,
        marketTrackType: null,
        content: null,
        enclosureInfoList: [], // 客户反馈内容
        enclosureImgList: [], // 费用票据
        fillId: null,
        fillName: null,
        fillTime: null,
        payType: null,
        money: null,
        paymentType: null,
        remark: null
      },
      todo: '' // 当前弹框类型， add:新增 ； edit：编辑 ； view：查看
    }
  },
  components: {
    bigImage
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    },
    isViews () {
      return this.todo === 'view'
    },
    isShow () {
      return this.todo === 'add'
    }
  },
  methods: {
    clearDataForm (that) {
      that.dataForm.customerId = null
      that.dataForm.customerContactName = null
      that.dataForm.customerContactPhone = null
      that.dataForm.customerContactPosition = null
      that.dataForm.customerContactId = null
      that.dataForm.marketTrackType = null
      that.dataForm.content = null
      that.dataForm.enclosureInfoList = [] // 客户反馈内容
      that.dataForm.enclosureImgList = [] // 费用票据
      that.dataForm.fillId = null
      that.dataForm.fillName = null
      that.dataForm.fillTime = null
      that.dataForm.payType = null
      that.dataForm.money = null
      that.dataForm.paymentType = null
    },
    init (todo, id) {
      let that = this
      this.visible = true
      this.uploadUrl = this.$http.adornUrl('/upload/file')
      this.accessToken = this.$cookie.get('TmsToken')
      this.licenseFileList = []
      this.clearDataForm(that)
      this.dataForm.id = id
      this.todo = todo
      // 拿到当前登录用户名和ID
      this.dataForm.fillId = this.currentUser.id
      this.dataForm.fillName = this.currentUser.realName
      if (!this.newAuth('create:special:addAll')) {
        this.isCustomer = true
        this.dataForm.customerIdList = this.currentUser.customerIdList
      }
      let params = null
      if (this.isCustomer) { // 判断是不是查看所有
        let param = {
          'ids': this.dataForm.customerIdList
        }
        params = qs.stringify(param, { arrayFormat: 'repeat' })
      }
      this.$http({
        url: this.$http.adornUrl(!this.isCustomer ? '/customer/market/findAll' : '/customer/market/findAll?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.customerList = data
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/marketTrack/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            if (data) {
              this.dataForm.customerId = data.customerId
              this.dataForm.customerContactId = data.customerContactId
              this.dataForm.customerContactName = data.customerContactName
              this.dataForm.customerContactPhone = data.customerContactPhone
              this.dataForm.customerContactPosition = data.customerContactPosition
              this.dataForm.marketTrackType = data.marketTrackType
              this.dataForm.enclosureImgList = data.enclosureImgList
              this.dataForm.enclosureInfoList = data.enclosureInfoList
              this.dataForm.content = data.content
              this.dataForm.fillId = data.fillId
              this.dataForm.fillName = data.fillName
              this.dataForm.fillTime = data.fillTime
              this.dataForm.payType = data.payType
              this.dataForm.paymentType = data.paymentType
              this.dataForm.money = data.money
              this.initFileList(data)
            }
          })
        }
      })
    },
    // 客户名称改变时，客户要带出联系人、联系电话、带出省市区下拉、运费清0
    changeCustomer (init) {
      this.contactNameOption = []
      if (!init) {
        this.dataForm.customerContactName = null
        this.dataForm.customerContactPhone = null
        this.dataForm.customerContactPosition = null
        this.dataForm.customerContactId = null
      }
      if (!this.dataForm.customerId) {
        return
      }
      this.$http({
        url: this.$http.adornUrl(`/customer/findContactList/${this.dataForm.customerId}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // 将值存入联系人、联系人电话下拉搜索框中
        data.forEach(item => {
          this.contactNameOption.push({
            contactName: item.contactName,
            contactPhone: item.contactPhone,
            position: item.position,
            contactId: item.id
          })
        })
        // 选择客户名称时有联系人信息则将第一个联系人信息赋值
        if (!init && this.contactNameOption[0]) {
          this.dataForm.customerContactName = this.contactNameOption[0].contactName
          this.dataForm.customerContactPhone = this.contactNameOption[0].contactPhone
          this.dataForm.customerContactPosition = this.contactNameOption[0].position
          this.dataForm.customerContactId = this.contactNameOption[0].contactId
        }
      })
    },
    // 初始化客户下拉
    getCustomerList () {
      if (!this.newAuth('create:special:addAll')) {
        this.isCustomer = true
        this.dataForm.customerIdList = this.currentUser.customerIdList
      }
      let params = null
      if (this.isCustomer) { // 判断是不是查看所有
        let param = {
          'ids': this.dataForm.customerIdList
        }
        params = qs.stringify(param, { arrayFormat: 'repeat' })
      }
      this.$http({
        url: this.$http.adornUrl(!this.isCustomer ? '/customer/findAll' : '/customer/findAll?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.customerList = data
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      })
    },
    createFilter (queryString, name) {
      return (restaurant) => {
        if (restaurant[name]) {
          return (restaurant[name].indexOf(queryString) === 0)
        }
        return false
      }
    },
    queryNameSearch (queryString, cb) {
      let results = queryString ? this.contactNameOption.filter(this.createFilter(queryString, 'contactName')) : this.contactNameOption
      if (!results.length) {
        results = [{
          value: ''
        }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    nameSelect (data) {
      this.dataForm.customerContactPosition = data.position
      this.dataForm.customerContactId = data.contactId
    },
    getFileData (data) {
      this.dataForm.enclosureInfoList = data.fileList
    },
    getImgData (data) {
      this.dataForm.enclosureImgList = data.fileList
    },
    handlePictureCardPreview (data) {
      this.$refs.bigImg.init(data.url)
    },
    // 图片预览
    preview (typeList, id) {
      this.$refs.viewPhoto.init(id, typeList)
    },
    initFileList (data) {
      let infoList = data.enclosureInfoList
      if (infoList) {
        this.dataForm.enclosureInfoList = infoList
        // 不直接赋值的原因是引用的是一个数组，删除会有问题(表面删除了实际数据没有)
        for (let i = 0; i < infoList.length; i++) {
          this.licenseFileList.push({ id: infoList[i].id, name: infoList[i].name, url: infoList[i].url })
        }
      }
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      if (file.type === this.enclosureDto.excel13Type ||
        file.type === this.enclosureDto.excel07Type ||
        file.type === this.enclosureDto.pdfType) {
        file.url = resData.url
      }
      this.dataForm.enclosureImgList.push({ id: resData.id, businessType: resData.businessType })
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    },
    // 表单提交
    dataFormSubmit (todo) {
      if (todo === 'view') {
        this.visible = false
      } else {
        // 附件合并一下
        this.dataForm.enclosureInfoList.push(...this.dataForm.enclosureImgList)
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            let methodName = !this.dataForm.id ? 'add' : 'update'
            let method = !this.dataForm.id ? 'put' : 'post'
            this.$http({
              url: this.$http.adornUrl(`/customer/track/` + methodName),
              method: method,
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'customerId': this.dataForm.customerId,
                'customerContactName': this.dataForm.customerContactName,
                'customerContactPhone': this.dataForm.customerContactPhone,
                'customerContactPosition': this.dataForm.customerContactPosition,
                'customerContactId': this.dataForm.customerContactId,
                'marketTrackType': this.dataForm.marketTrackType,
                'content': this.dataForm.content,
                'fillId': this.dataForm.fillId,
                'fillName': this.dataForm.fillName,
                'fillTime': this.dataForm.fillTime,
                'payType': this.dataForm.payType,
                'money': this.dataForm.money,
                'paymentType': this.dataForm.paymentType,
                'enclosureInfoList': this.dataForm.enclosureInfoList,
                'remark': this.dataForm.remark
              })
            }).then(({ data }) => {
              if (data) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error('操作失败')
              }
            })
          }
        })
      }
    }
  }
}
</script>
